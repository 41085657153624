import React, { Fragment, useMemo, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'reducers/index';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import NAV_DATA, {
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import {
  babyPreAuthPrimaryNavData,
  babyPostAuthPrimaryNavData,
} from 'components/BabyNav/babyNavData';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';

import { signout } from 'actions/AuthActions';
import { showModal } from 'actions/ModalActions';

import DownloadButton from 'src/assets/images/download-button.png';

import sharedStyles from '../sharedStyles.module.less';
import styles from './mobileVerticalNav.module.less';

const APP_DOWNLOAD_HREF =
  'https://apps.apple.com/app/apple-store/id6446598574?pt=31209800&ct=zola_web_nav&mt=8';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

type MobileVerticalNavProps = {
  isVerticalNavOpen?: boolean;
  openVerticalNav: (open: boolean) => void;
  openVerticalNavAccount: (open: boolean) => void;
  secondaryNavData: SecondaryNavItem[];
  toggleSearch: () => void;
  userContext?: UserContext;
};

export const MobileVerticalNav = ({
  isVerticalNavOpen,
  openVerticalNav,
  openVerticalNavAccount,
  secondaryNavData,
  toggleSearch,
  userContext,
}: MobileVerticalNavProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [openDropdown, setOpenDropdown] = useState(false);

  const isGuest = !userContext?.has_baby_registry;

  // Nest secondary nav items within the baby shop primary nav item if available
  const navItems = useMemo(() => {
    const primaryNavItems = isGuest
      ? babyPreAuthPrimaryNavData
      : babyPostAuthPrimaryNavData(userContext);
    const babyShopNavItemIndex = primaryNavItems.findIndex(
      (item) => item.id === PrimaryNavIdentifier.SHOP
    );
    return secondaryNavData.length > 0
      ? [
          ...primaryNavItems.slice(0, babyShopNavItemIndex),
          { ...primaryNavItems[babyShopNavItemIndex], secondaryNavItems: secondaryNavData },
          ...primaryNavItems.slice(babyShopNavItemIndex + 1),
        ]
      : primaryNavItems;
  }, [isGuest, secondaryNavData, userContext]);

  const showLoginModal = () => {
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        {
          authState: 'login',
          hideVendorLogin: true,
          loginCallback: () => {
            return window.location.reload();
          },
          signupLinkCallback: () => window.location.assign('/onboard'),
        },
        { size: 'sm', ariaLabel: 'Log in', v2Styles: true }
      )
    );
  };

  const showBabyChecklistDrawer = (e: React.MouseEvent<Element>) => {
    e.preventDefault();
    window.dispatchEvent(new CustomEvent('OPEN_BABY_CHECKLIST_DRAWER'));
  };

  return (
    <>
      <div
        className={cx(styles.overlay, { [styles.overlayOpen]: isVerticalNavOpen })}
        onClick={() => {
          openVerticalNav(false);
          openVerticalNavAccount(false);
        }}
        onKeyDown={() => null}
      />
      <div
        className={cx(sharedStyles.verticalNav, {
          [sharedStyles.verticalNavOpen]: isVerticalNavOpen === true,
          [sharedStyles.verticalNavClose]: isVerticalNavOpen === false,
        })}
      >
        <div
          className={cx(sharedStyles.verticalNavInner, {
            [sharedStyles.verticalNavInnerOpen]: isVerticalNavOpen === true,
            [sharedStyles.verticalNavInnerClose]: isVerticalNavOpen === false,
          })}
        >
          <div className={sharedStyles.center}>
            <a aria-label="Zola Baby homepage" href="/">
              <ZolaBabyLogo />
            </a>
            <LinkV2 aria-label="Close menu" role="button" onClick={() => openVerticalNav(false)}>
              <XIcon height={20} title="" width={20} />
            </LinkV2>
          </div>
          <ButtonV3
            className={styles.searchInputButton}
            onClick={toggleSearch}
            variant="transparent"
          >
            <SearchIcon className={styles.searchIcon} height={20} title="" width={20} />
            Search registries, brands & products...
          </ButtonV3>
          {navItems.map((primaryNavItem, primaryNavItemIndex) => (
            <Fragment key={`primary-${primaryNavItemIndex}`}>
              <div
                {...('secondaryNavItems' in primaryNavItem
                  ? {
                      'aria-controls': `dropdown-${primaryNavItem.path}`,
                      'aria-expanded': openDropdown,
                      onClick: () => setOpenDropdown(!openDropdown),
                      onKeyDown: (e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          setOpenDropdown(!openDropdown);
                        }
                      },
                      role: 'button',
                      tabIndex: 0,
                    }
                  : {})}
                className={styles.navLink}
              >
                <LinkV2
                  className={sharedStyles.link}
                  href={primaryNavItem.path}
                  noUnderline
                  sizes="regular"
                  subtle
                  {...(primaryNavItem.id === PrimaryNavIdentifier.CHECKLIST
                    ? { onClick: showBabyChecklistDrawer }
                    : {})}
                >
                  {primaryNavItem.title}
                </LinkV2>
                {'secondaryNavItems' in primaryNavItem && (
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={cx(styles.chevronIcon, {
                      [styles.open]: openDropdown,
                    })}
                    height={20}
                    title=""
                    width={20}
                  />
                )}
              </div>
              {'secondaryNavItems' in primaryNavItem && (
                <div
                  className={cx(styles.dropdown, {
                    [styles.open]: openDropdown,
                  })}
                  id={`dropdown-${primaryNavItem.path}`}
                >
                  {primaryNavItem.secondaryNavItems.map(
                    (secondaryNavItem, secondaryNavItemIndex) => (
                      <LinkV2
                        className={cx(styles.dropdownLink, sharedStyles.link)}
                        href={secondaryNavItem.path}
                        key={`secondary-${secondaryNavItemIndex}`}
                        noUnderline
                        sizes="small"
                        subtle
                      >
                        {secondaryNavItem.title}
                      </LinkV2>
                    )
                  )}
                </div>
              )}
            </Fragment>
          ))}
          <div className={styles.bottomLinks}>
            {!isGuest && (
              <LinkV2
                className={cx(styles.bottomLink, sharedStyles.link, sharedStyles.center)}
                noUnderline
                onClick={() => openVerticalNavAccount(true)}
                role="button"
                sizes="small"
                subtle
              >
                Account settings
                <ChevronRightIcon aria-hidden="true" height={16} title="" width={16} />
              </LinkV2>
            )}
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href={APP_DOWNLOAD_HREF}
              noUnderline
              rel="noopener noreferrer"
              sizes="small"
              subtle
              target="_blank"
            >
              Baby Mobile App
            </LinkV2>
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href="/contact-us"
              noUnderline
              sizes="small"
              subtle
            >
              Contact us
            </LinkV2>
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href="/faq"
              noUnderline
              sizes="small"
              subtle
            >
              FAQs
            </LinkV2>
          </div>
          <div className={styles.bottomButtons}>
            {isGuest ? (
              <>
                <ButtonV3 onClick={showLoginModal} size="large" variant="secondary">
                  Log in
                </ButtonV3>
                <ButtonV3 component="a" href="/onboard" size="large">
                  Get started
                </ButtonV3>
              </>
            ) : (
              <ButtonV3
                onClick={() => {
                  dispatch(signout()).catch(() => null);
                }}
                size="large"
                variant="secondary"
              >
                Log out
              </ButtonV3>
            )}
            <a
              aria-label="Download the Zola Baby app"
              className={styles.downloadButton}
              href={APP_DOWNLOAD_HREF}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="" src={DownloadButton} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
