import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import type { BabyChecklistCategoryView } from 'components/BabyNav/ChecklistDrawer/types';
import type { WBabyChecklistSubcategoryView } from '@zola/svc-web-api-ts-client';

import P from '@zola/zola-ui/src/typography/Paragraphs';
import TagV2, { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import ToggleV2 from '@zola/zola-ui/src/components/ToggleV2';

import sharedStyles from './checklistDrawer.module.less';
import styles from './checklistDrawerCategoryEdit.module.less';

type ChecklistDrawerCategoryEditProps = {
  checklistSubcategoriesToUpdate: WBabyChecklistSubcategoryView[];
  currentChecklistCategory: BabyChecklistCategoryView;
  setChecklistSubcategoriesToUpdate: (
    subcategoriesToUpdate: WBabyChecklistSubcategoryView[]
  ) => void;
};

/**
 * Baby checklist drawer layer that allows edit of a category
 */
export const ChecklistDrawerCategoryEdit = ({
  checklistSubcategoriesToUpdate,
  currentChecklistCategory,
  setChecklistSubcategoriesToUpdate,
}: ChecklistDrawerCategoryEditProps): JSX.Element => {
  const { subcategories } = currentChecklistCategory;
  const [localSubcategories, setLocalSubcategories] = useState(subcategories);

  // Reset subcategories that were queued to update via API requests on unmounting this drawer layer
  useEffect(() => {
    return () => {
      setChecklistSubcategoriesToUpdate([]);
    };
  }, [setChecklistSubcategoriesToUpdate]);

  const handleUpdateBabyChecklistSubcategory = (subcategory: WBabyChecklistSubcategoryView) => {
    // Anticipates API requests to update hidden status of subcategories on saving drawer edits
    const subcategoriesToUpdate = (() => {
      const index = checklistSubcategoriesToUpdate.findIndex(
        (item) => item.uuid === subcategory.uuid
      );
      if (index !== -1) {
        return checklistSubcategoriesToUpdate.filter((item) => item.uuid !== subcategory.uuid);
      }
      return [...checklistSubcategoriesToUpdate, subcategory];
    })();
    setChecklistSubcategoriesToUpdate(subcategoriesToUpdate);

    // Immediate client-side update of subcategory hidden status prior to saving drawer edits
    const updatedLocalSubcategories = localSubcategories?.map((item) =>
      item.uuid === subcategory.uuid ? { ...item, hidden: !item.hidden } : item
    );
    setLocalSubcategories(updatedLocalSubcategories);
  };

  return localSubcategories?.length && localSubcategories.length > 0 ? (
    <div>
      <div className={sharedStyles.subtitle}>
        <P.BodyBase>To hide items from your checklist, toggle them off below</P.BodyBase>
      </div>
      <ul>
        {localSubcategories.map((subcategory, index) => {
          return (
            <li className={cx(sharedStyles.drawerListItem, styles.subcategory)} key={index}>
              <div className={sharedStyles.drawerItem}>
                <div>{subcategory.label}</div>
                <div className={styles.drawerItemRight}>
                  {subcategory.essential ? (
                    <TagV2 size={TagV2Size.SMALL} variant={TagV2Variant.NEON_LIME}>
                      Essential
                    </TagV2>
                  ) : null}
                  <ToggleV2
                    className={styles.toggle}
                    id={`baby-checklist-toggle-${subcategory.taxonomy_node_key}`}
                    inline
                    onChange={() => handleUpdateBabyChecklistSubcategory(subcategory)}
                    value={!subcategory.hidden}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <></>
  );
};
