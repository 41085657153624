import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';

/**
 * @deprecated - Prefer tracking contracts
 */
export const trackProductClicked = (productData: unknown) => {
  trackEvent('Product Clicked', productData);
};

export const trackSearchSuggestionClicked = (eventData: unknown) => {
  trackEvent('Search Suggestion Clicked', eventData);
};

export const trackProductsSearched = (eventData: unknown) => {
  trackEvent('Products Searched', eventData);
};

export const trackSearchTabClicked = (eventData: unknown) => {
  trackEvent('Search Tab Clicked', eventData);
};

export const trackModalViewed = (eventData: unknown) => {
  trackEvent('Modal Viewed', eventData);
};

export const trackModalClicked = (eventData: unknown) => {
  trackEvent('Modal Clicked', eventData);
};
