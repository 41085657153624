import type { BabyChecklistView } from 'components/BabyNav/ChecklistDrawer/types';
import type {
  WBabyChecklistSubcategoryView,
  WBabyChecklistView,
} from '@zola/svc-web-api-ts-client';

/**
 * Maps the baby checklist response object to a client-friendly format. Adds `completedCount`
 * and `totalCount` to each category to make the count calculations easily accessible
 */
export const mapChecklist = (response: WBabyChecklistView): BabyChecklistView => ({
  ...response,
  categories: response.categories?.map((category) => ({
    ...category,
    completedCount:
      category.subcategories?.filter((subcategory) => subcategory.completed && !subcategory.hidden)
        .length || 0,
    totalCount: category.subcategories?.filter((subcategory) => !subcategory.hidden).length || 0,
  })),
});

/**
 * Recalculates `completedCount` and `totalCount` for each category within
 * the checklist object after changing the completed status of a subcategory
 */
export const mapChecklistWithCompletedSubcategory = ({
  checklist,
  updatedCategoryUuid,
  updatedSubcategoryCompleted,
  updatedSubcategoryUuid,
}: {
  checklist: BabyChecklistView;
  updatedCategoryUuid: string;
  updatedSubcategoryCompleted: boolean;
  updatedSubcategoryUuid: string;
}) => ({
  ...checklist,
  categories: checklist.categories?.map((category) => {
    if (category.uuid === updatedCategoryUuid) {
      const updatedSubcategories = category.subcategories?.map((subcategory) =>
        subcategory.uuid === updatedSubcategoryUuid
          ? { ...subcategory, completed: updatedSubcategoryCompleted }
          : subcategory
      );
      const completedCount =
        (category.subcategories?.filter(
          (subcategory) => subcategory.completed && !subcategory.hidden
        ).length || 0) + (updatedSubcategoryCompleted ? 1 : -1);

      return {
        ...category,
        completedCount,
        subcategories: updatedSubcategories,
      };
    }
    return category;
  }),
});

/**
 * Recalculates `completedCount` and `totalCount` for each category within
 * the checklist object after changing the hidden status of subcategories
 */
export const mapChecklistWithHiddenSubcategories = ({
  checklist,
  checklistSubcategoriesToUpdate,
  updatedCategoryUuid,
}: {
  checklist: BabyChecklistView;
  checklistSubcategoriesToUpdate: WBabyChecklistSubcategoryView[];
  updatedCategoryUuid: string;
}): BabyChecklistView => ({
  ...checklist,
  categories: checklist.categories?.map((category) => {
    if (category.uuid !== updatedCategoryUuid) return category;

    const updatedSubcategories = category.subcategories?.map((subcategory) => {
      const updatedSubcategory = checklistSubcategoriesToUpdate.find(
        (item) => item.uuid === subcategory.uuid
      );
      return updatedSubcategory
        ? { ...subcategory, hidden: !updatedSubcategory.hidden }
        : subcategory;
    });

    const visibleSubcategories =
      updatedSubcategories?.filter((subcategory) => !subcategory.hidden) || [];

    const completedCount = visibleSubcategories.filter(
      (subcategory) => subcategory.completed
    ).length;

    return {
      ...category,
      completedCount,
      subcategories: updatedSubcategories,
      totalCount: visibleSubcategories.length,
    };
  }),
});
