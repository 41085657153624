import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'reducers/index';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import DropdownV3 from '@zola/zola-ui/src/components/Form/dropdownV3';
import { UserIcon } from '@zola/zola-ui/src/components/SvgIconsV3/User';

import { babyAccountNavData } from 'components/BabyNav/babyNavData';
import { signout } from 'actions/AuthActions';

import styles from './accountDropdown.module.less';

type AccountDropdownProps = {
  userContext?: UserContext;
};

export const AccountDropdown = ({ userContext }: AccountDropdownProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const userInitials = useMemo(() => {
    const { first_name = '', last_name = '' } = userContext || {};
    const [firstNameInitial] = first_name;
    const [lastNameInitial] = last_name;
    return firstNameInitial && lastNameInitial
      ? `${firstNameInitial}${lastNameInitial}`.toUpperCase()
      : '';
  }, [userContext]);

  const handleAccountDropdownClick = async (selectedPath: string | number | null) => {
    const shouldLogOut = selectedPath === '/';

    if (shouldLogOut) {
      await dispatch(signout());
    }

    window.location.assign(selectedPath as string);
  };

  return (
    <DropdownV3
      boxAlign="right"
      boxWidth="201px"
      className={styles.accountDropdown}
      isHoverTrigger
      onClick={() => {
        handleAccountDropdownClick('/account/settings/info').catch(() => null);
      }}
      onSelect={(selectedPath) => {
        handleAccountDropdownClick(selectedPath).catch(() => null);
      }}
      options={babyAccountNavData}
      trigger={
        <ButtonV3 className={styles.accountCircle} textTransform={false} variant="secondary">
          {userInitials || <UserIcon height={20} title="" width={20} />}
        </ButtonV3>
      }
    />
  );
};
