import { connect } from 'react-redux';

import { getUserContext } from 'selectors/user';
import * as SearchActions from '../../../../actions/SearchActions';
import SearchContainer from './SearchContainer';

export function mapStateToProps(state) {
  return {
    coupleSearchResults: state.search.couplesResults,
    vendorSearchResults: state.search.vendorsResults,
    productsSuggestionsResults: state.search.productsSuggestionsResults,
    userContext: getUserContext(state),
    loading: state.search.busy,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    handleCouplesSearch: (word) => dispatch(SearchActions.getCouples(word)),
    handleResetCouplesSearch: (word) => dispatch(SearchActions.resetCouples(word)),
    handleProductsSuggestions: (word) => dispatch(SearchActions.getProductsSuggestions(word)),
    handleResetProductsSuggestions: (word) =>
      dispatch(SearchActions.resetProductsSuggestions(word)),
    handleSetSearchWord: (word) => dispatch(SearchActions.setSearchWord(word)),
    handleVendorsSearch: (word) => dispatch(SearchActions.getVendorSearchResults(word)),
    handleResetVendorsSearch: (word) => dispatch(SearchActions.resetVendors(word)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
