import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getUserContext } from 'selectors/user';
import { maybeFetchUser } from 'actions/UserActions';
import useNavData from 'components/navV2/useNavData';
import { BabyNavContextProvider } from '../../contexts/BabyNavContext';
import useScrollPosition from '../../hooks/useScrollPosition';
import type { RootState, AppDispatch } from '../../reducers';

import { Banner } from './Banner/Banner';
import { ChecklistDrawer } from './ChecklistDrawer/ChecklistDrawer';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';

import styles from './babyNav.module.less';
import '../navV2/navV2.less';

type BabyNavProps = NavV2MapStateProps &
  NavV2MapDispatchProps & {
    legacy: boolean;
  };

const BabyNav = ({
  userContext,
  maybeFetchUser: maybeFetchUserFn,
  legacy = false,
}: BabyNavProps): JSX.Element => {
  const userContextExists = userContext && !_isEmpty(userContext);
  const defaultScrollPos = (typeof window !== 'undefined' && window?.scrollY) || 0;
  const [hideContentOnScrollDown, setHideContentOnScrollDown] = useState(defaultScrollPos !== 0);
  const [hideContentUntilScrolledToTop, setHideContentUntilScrolledToTop] = useState(
    defaultScrollPos !== 0
  );
  const { direction } = useScrollPosition();

  const currentHideContentOnScrollDown = direction === 'down' && defaultScrollPos > 0;
  const currentHideContentUntilScrolledToTop =
    (direction === 'down' || direction === 'up') && defaultScrollPos > 0;

  useEffect(() => {
    if (currentHideContentOnScrollDown !== hideContentOnScrollDown) {
      setHideContentOnScrollDown(currentHideContentOnScrollDown);
    }
    if (currentHideContentUntilScrolledToTop !== hideContentUntilScrolledToTop) {
      setHideContentUntilScrolledToTop(currentHideContentUntilScrolledToTop);
    }
  }, [
    currentHideContentOnScrollDown,
    currentHideContentUntilScrolledToTop,
    hideContentOnScrollDown,
    hideContentUntilScrolledToTop,
  ]);

  // useCallback so the referential check in useNavData's useEffect works as expected
  const onInitNavData = useCallback((passedUserContext: UserContext = {}) => {
    // if (!_isEmpty(passedUserContext)) addUserContextToRedux(passedUserContext);
    console.log(passedUserContext);
  }, []);

  const { primaryLinkId, secondaryLinkId, secondaryNavData } = useNavData(
    onInitNavData,
    userContext,
    ''
  );

  if (!userContextExists) {
    maybeFetchUserFn().catch(() => null);
    return <Fragment />;
  }

  return (
    <BabyNavContextProvider legacy={legacy}>
      <nav className={styles.babyNav}>
        <Banner hideContentUntilScrolledToTop={hideContentUntilScrolledToTop} />
        <DesktopNav
          hideContentOnScrollDown={hideContentOnScrollDown}
          primaryLinkId={primaryLinkId}
          secondaryLinkId={secondaryLinkId}
          secondaryNavData={secondaryNavData}
          userContext={userContext}
        />
        {!legacy && (
          <>
            <MobileNav
              hideContentOnScrollDown={hideContentOnScrollDown}
              secondaryLinkId={secondaryLinkId}
              secondaryNavData={secondaryNavData}
              userContext={userContext}
            />
            <ChecklistDrawer secondaryLinkId={secondaryLinkId} userContext={userContext} />
          </>
        )}
      </nav>
    </BabyNavContextProvider>
  );
};

type NavV2MapStateProps = {
  userContext?: UserContext;
};

const mapStateToProps = (state: RootState): NavV2MapStateProps => ({
  userContext: getUserContext(state),
});

type NavV2MapDispatchProps = {
  // addUserContextToRedux: (userContext: UserContext) => void;
  maybeFetchUser: (shouldForceUserFetch?: boolean) => Promise<UserContext | void>;
};

const mapDispatchToProps = (dispatch: AppDispatch): NavV2MapDispatchProps => ({
  // addUserContextToRedux: (userContext) => {
  //   dispatch(receiveUser(userContext));
  // },
  maybeFetchUser: (shouldForceUserFetch) => dispatch(maybeFetchUser(shouldForceUserFetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BabyNav);
