import React, { Suspense, useState } from 'react';

import { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import SearchContainer from 'components/nav/Search/SearchContainer';
import { MobilePrimaryNav } from 'components/BabyNav/MobilePrimaryNav/MobilePrimaryNav';
import { MobileSecondaryNav } from 'components/BabyNav/MobileSecondaryNav/MobileSecondaryNav';
import { MobileVerticalNav } from 'components/BabyNav/MobileVerticalNav/MobileVerticalNav';
import { MobileVerticalNavAccount } from 'components/BabyNav/MobileVerticalNavAccount/MobileVerticalNavAccount';

import styles from './mobileNav.module.less';

type MobileNavProps = {
  hideContentOnScrollDown: boolean;
  secondaryLinkId: string;
  secondaryNavData: SecondaryNavItem[];
  userContext?: UserContext;
};

export const MobileNav = ({
  hideContentOnScrollDown,
  secondaryLinkId,
  secondaryNavData,
  userContext,
}: MobileNavProps): JSX.Element => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isVerticalNavOpen, setIsVerticalNavOpen] = useState<boolean | undefined>(undefined);
  const [isVerticalNavAccountOpen, setIsVerticalNavAccountOpen] = useState<boolean | undefined>(
    undefined
  );

  const openVerticalNav = (open = true) => {
    setIsVerticalNavOpen(open);
  };

  const openVerticalNavAccount = (open = true) => {
    setIsVerticalNavAccountOpen(open);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className={styles.mobileNav}>
      <MobilePrimaryNav
        hideContentOnScrollDown={hideContentOnScrollDown}
        openVerticalNav={openVerticalNav}
      />
      {secondaryNavData.length > 0 && (
        <MobileSecondaryNav secondaryLinkId={secondaryLinkId} secondaryNavData={secondaryNavData} />
      )}
      <MobileVerticalNav
        isVerticalNavOpen={isVerticalNavOpen}
        openVerticalNav={openVerticalNav}
        openVerticalNavAccount={openVerticalNavAccount}
        secondaryNavData={secondaryNavData}
        toggleSearch={toggleSearch}
        userContext={userContext}
      />
      <MobileVerticalNavAccount
        isVerticalNavAccountOpen={isVerticalNavAccountOpen}
        openVerticalNav={openVerticalNav}
        openVerticalNavAccount={openVerticalNavAccount}
        userContext={userContext}
      />
      {isSearchOpen ? (
        <Suspense fallback={<div>Loading search result</div>}>
          <div className={styles.searchContainer}>
            <SearchContainer
              isActive={isSearchOpen}
              toggleSearchActive={toggleSearch}
              notDesktop
              defaultCategory="couples"
              isWeddingStoreActive
            />
          </div>
        </Suspense>
      ) : null}
    </div>
  );
};
