import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import TagV2, { TagV2Size } from '@zola/zola-ui/src/components/Tag/TagV2';
import { BoxIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Box';
import { Discount2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Discount2';
import { TagMoneyIcon } from '@zola/zola-ui/src/components/SvgIconsV3/TagMoney';

import featureFlags from 'util/featureFlags';
import { getZolaHref } from 'util/getZolaHref';
import { useBabyNavContext } from '../../../contexts/BabyNavContext';

import styles from './banner.module.less';

// TODO: Update to be dynamic once BE is able to target baby for top nav messages
const PERKS = [
  { icon: BoxIcon, text: 'Free returns' },
  { icon: TagMoneyIcon, text: 'Guaranteed price matching' },
  { icon: Discount2Icon, text: '15% off after arrival' },
];
const PROMO = '10% off all products with code PROMO10 ends 05/01';

type BannerProps = {
  hideContentUntilScrolledToTop: boolean;
};

export const Banner = ({ hideContentUntilScrolledToTop }: BannerProps): JSX.Element => {
  const [perkIndex, setPerkIndex] = useState(0);

  const { legacy } = useBabyNavContext();

  // Cycle through perks
  useEffect(() => {
    const interval = setInterval(() => {
      setPerkIndex((prevIndex) => (prevIndex + 1) % PERKS.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  if (!legacy) {
    return (
      <div
        className={cx(styles.banner, {
          [styles.hideContentOnScroll]: hideContentUntilScrolledToTop,
        })}
      >
        <div className={styles.links}>
          <LinkV2
            className={styles.tanColor}
            href={getZolaHref(featureFlags.getEnvironment())}
            noUnderline
            sizes="extraSmall"
            subtle
            target="_blank"
          >
            Zola Weddings
          </LinkV2>
          <LinkV2 className={styles.tanColor} href="/" sizes="extraSmall" subtle>
            Zola Baby
          </LinkV2>
        </div>
        <div className={styles.promo}>
          <TagV2 className={styles.promoTag} size={TagV2Size.SMALL}>
            Sale
          </TagV2>
          <P.BodySmall className={styles.tanColor}>{PROMO}</P.BodySmall>
          <LinkV2
            className={cx(styles.promoTerms, styles.tanColor)}
            noUnderline
            role="button"
            sizes="extraSmall"
            subtle
          >
            Terms
          </LinkV2>
        </div>
        <div className={styles.perks}>
          {PERKS.map((perk, index) => (
            <div
              className={cx(styles.perk, { [styles.show]: perkIndex === index })}
              key={perk.text}
            >
              {perk.icon && <perk.icon title="" />}
              <P.BodySmall className={styles.tanColor}>{perk.text}</P.BodySmall>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.oldBanner, {
        [styles.hideContentOnScroll]: hideContentUntilScrolledToTop,
      })}
    >
      <P.BodySmall>You’re on Zola’s baby registry site. Looking for Zola Weddings?</P.BodySmall>
      <LinkV2 href={getZolaHref(featureFlags.getEnvironment())} sizes="smaller">
        Go to Zola Weddings
      </LinkV2>
    </div>
  );
};
