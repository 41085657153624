import { clearExperimentStorage } from '@zola-helpers/client/dist/es/experiments';
import { setGTMLoggedIn } from '@zola-helpers/client/dist/es/tracking/googleTagManager';
import { deleteCookie, deleteSessionStorage } from '@zola-helpers/client/dist/es/util/storage';
import { BusinessUnit } from '@zola-helpers/client/dist/es/@types';
import {
  sendSignOutRequest,
  loginSuccess,
  logout,
} from '@zola-helpers/client/dist/es/redux/auth/authActions';
import {
  identifyUserSailthru,
  createStoreAccount,
} from '@zola-helpers/client/dist/es/redux/user/userActions';
import detectBusinessUnit from '@zola-helpers/client/dist/es/util/detectBusinessUnit';

import { AppDispatch, RootState } from 'reducers/index';
import { getUserContext as getUserContextSelector } from 'selectors/user';

import { getUserContext, onIdentifyUser } from './UserActions';

export function onAnySuccessfulLogin() {
  return (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    return dispatch(getUserContext())
      .then(() => {
        const isHomeStore = detectBusinessUnit() === BusinessUnit.STORE;
        const newState = getState();
        const uc = getUserContextSelector(newState);
        const hasStoreAccount = uc?.has_store_account;
        if (isHomeStore && !hasStoreAccount) {
          dispatch(createStoreAccount()).catch((err) => {
            throw err;
          });
        }
        clearExperimentStorage();
        const { email } = uc;
        setGTMLoggedIn();
        dispatch(onIdentifyUser());
        if (email) {
          dispatch(loginSuccess(email));
          identifyUserSailthru(email).catch(() => undefined);
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function signout() {
  return (dispatch: AppDispatch): Promise<void> => {
    return sendSignOutRequest().then(() => {
      // remove the session keys that are set in web-zola that are related to planner progress bar (set in progressBar.js);
      const PLANNER_ONBOARDING_KEY = 'planner.checkedForOnboarding';
      const PLANNER_RESULTS_KEY = 'planner.results';
      deleteSessionStorage(PLANNER_ONBOARDING_KEY);
      deleteSessionStorage(PLANNER_RESULTS_KEY);

      // remove impact radius clickId
      deleteCookie('clickId');
      deleteCookie('pauth-clickId');

      dispatch(logout());
    });
  };
}
