import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';
import { linkFor } from '@zola-helpers/client/dist/es/util/accountSettingsNav';

import UnderlinePostAuth1 from 'assets/images/baby/underline-post-auth-1.svg';
import UnderlinePostAuth2 from 'assets/images/baby/underline-post-auth-2.svg';
import UnderlinePostAuth3 from 'assets/images/baby/underline-post-auth-3.svg';
import UnderlinePreAuth1 from 'assets/images/baby/underline-pre-auth-1.svg';
import UnderlinePreAuth2 from 'assets/images/baby/underline-pre-auth-2.svg';
import UnderlinePreAuth3 from 'assets/images/baby/underline-pre-auth-3.svg';

export const babyPreAuthPrimaryNavData = [
  {
    id: PrimaryNavIdentifier.REGISTRY,
    path: '/',
    title: 'Baby registry',
    underlineAsset: UnderlinePreAuth1,
  },
  {
    id: PrimaryNavIdentifier.SHOP,
    path: '/shop/brands',
    title: 'Shop',
    underlineAsset: UnderlinePreAuth2,
  },
  {
    id: PrimaryNavIdentifier.FIND_A_REGISTRY,
    path: '/search/baby-registry',
    title: 'Find a registry',
    underlineAsset: UnderlinePreAuth3,
  },
];

export const babyPostAuthPrimaryNavData = (userContext?: UserContext) => [
  {
    id: PrimaryNavIdentifier.REGISTRY,
    path: '/my-registry',
    title: 'Your registry',
    underlineAsset: UnderlinePostAuth1,
  },
  {
    id: PrimaryNavIdentifier.SHOP,
    path: '/registry/add-gifts',
    title: 'Add gifts',
    underlineAsset: UnderlinePostAuth2,
  },
  ...insertInArrayIf(Boolean(userContext?.has_baby_registry), {
    id: PrimaryNavIdentifier.CHECKLIST,
    path: '/registry/checklist',
    title: 'Checklist',
    underlineAsset: UnderlinePostAuth2,
  }),
  {
    id: PrimaryNavIdentifier.GIFT_TRACKER,
    path: '/registry/gift-tracker',
    title: 'Gift tracker',
    underlineAsset: UnderlinePostAuth3,
  },
];

// TODO: Update paths once routes are available & check if any need to be conditional
export const babyAccountNavData = [
  {
    label: 'Your account',
    value: '/account/settings/info',
  },
  {
    label: 'Registry settings',
    value: '/account/settings',
  },
  {
    label: "Orders you've placed",
    value: '/account/settings/orders',
  },
  {
    label: 'Ratings and reviews',
    value: '/account/settings/reviews',
  },
  {
    label: 'Zola store credit',
    value: '/account/settings/credits',
  },
  {
    label: 'Privacy settings',
    value: '/account/settings/privacy',
  },
  {
    label: 'Switch registry',
    value: '/account/settings',
  },
  {
    label: 'New registry',
    value: '/account/settings',
  },
  {
    label: 'Log out',
    value: '/',
  },
];

// TODO: Update paths once routes are available & move to zola-helpers if needed within account settings
export const babyMobileAccountNavData = (userContext?: UserContext) => {
  return [
    {
      heading: 'Your account',
      links: [
        linkFor('info', 'Information', undefined),
        linkFor('password-security', 'Password and security', undefined),
        ...insertInArrayIf(
          Boolean(userContext?.has_baby_registry),
          linkFor('info', 'Your details', undefined)
        ),
        linkFor('invite-friends', 'Invite collaborator', undefined),
        linkFor('credits', 'Zola credits', undefined),
      ],
    },
    ...insertInArrayIf(Boolean(userContext?.has_baby_registry), {
      heading: 'Registry settings',
      links: [
        linkFor('privacy', 'Privacy settings', undefined),
        linkFor('shipping', 'Shipping address', undefined),
        linkFor('bank', 'Bank account info', undefined),
        linkFor('cash', 'Cash fund settings', undefined),
        linkFor('other-registries', 'Switch registries', undefined),
      ],
    }),
    {
      heading: 'Your orders',
      links: [
        linkFor('orders', 'Order history', undefined),
        linkFor('reviews', 'Ratings and reviews', undefined),
      ],
    },
  ];
};
